.ion-color-grey {
  --ion-color-base: var(--ion-color-grey);
  --ion-color-base-rgb: var(--ion-color-grey-rgb);
  --ion-color-contrast: var(--ion-color-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-shade);
  --ion-color-tint: var(--ion-color-grey-tint);
}

.ion-color-green {
  --ion-color-base: var(--ion-color-green);
  --ion-color-base-rgb: var(--ion-color-green-rgb);
  --ion-color-contrast: var(--ion-color-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-green);
  --ion-color-tint: var(--ion-color-green-tint);
}

.ion-color-green {
  --ion-color-base: var(--ion-color-green);
  --ion-color-base-rgb: var(--ion-color-green-rgb);
  --ion-color-contrast: var(--ion-color-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-green);
  --ion-color-tint: var(--ion-color-green-tint);
}

.ion-color-light-blue {
  --ion-color-base: var(--ion-color-light-blue);
  --ion-color-base-rgb: var(--ion-color-light-blue-rgb);
  --ion-color-contrast: var(--ion-color-light-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-blue);
  --ion-color-tint: var(--ion-color-light-blue-tint);
}

.ion-color-pink {
  --ion-color-base: var(--ion-color-pink);
  --ion-color-base-rgb: var(--ion-color-pink-rgb);
  --ion-color-contrast: var(--ion-color-pink-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pink-contrast-rgb);
  --ion-color-shade: var(--ion-color-pink-shade);
  --ion-color-tint: var(--ion-color-pink-tint);
}


.full-width {
  width: 100%;
}

/*
ion-toolbar.toolbar-compact {
  --padding-bottom: 0px;
  --padding-top: 0px;
  --min-height: 40px;

  ion-segment {
    padding-bottom: 0px;
    padding-top: 0px;
    --ripple-color: transparent;
  }
}*/


.is-background-primary {
  background-color: var(--ion-color-primary);
}

.is-background-success {
  background-color: var(--ion-color-success);
  color: var(--ion-color-success-contrast);
}

.is-background-secondary {
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);
}

.is-background-dark {
  background-color: var(--ion-color-dark);
  color: var(--ion-color-dark-contrast);
}

.is-background-light {
  background-color: var(--ion-color-light);
  color: var(--ion-color-light-contrast);
}

.is-background-danger {
  background-color: var(--ion-color-danger);
}

.is-color-primary {
  color: var(--ion-color-primary) !important;
}

.is-color-light {
  color: var(--ion-color-light) !important;
}

.is-color-secondary {
  color: var(--ion-color-secondary) !important;
}

.is-color-success {
  color: var(--ion-color-success) !important;
}

.is-color-danger {
  color: var(--ion-color-danger) !important;
}

.is-color-pink {
  color: var(--ion-color-pink) !important;
}

.is-login-element-dark-contrast {
  color: var(--is-login-element-dark-contrast);
}

@media (prefers-color-scheme: light) {
  .border-right {
    border-right: 1px solid var(--border-color)
  }
  .border-bottom {
    border-bottom: 1px solid var(--border-color)
  }

}

@media (prefers-color-scheme: dark) {
  .border-right {
    border-right: 1px solid var(--border-color)
  }
  .border-bottom {
    border-bottom: 1px solid var(--border-color)
  }
}

ion-fab-button[data-desc] {
  position: relative;
}

ion-fab-button[data-desc]::after {
  position: absolute;
  content: attr(data-desc);
  z-index: 1;
  right: 55px;
  bottom: 4px;
  background-color: var(--ion-color-light);
  padding: 9px;
  border-radius: 15px;
  color: var(--ion-color-light-contrast);
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

.text-bold {
  font-weight: 800;
}

.progress-bar {
  border: none;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: space-between;

  span {
    height: 9px;
    transition: width 5s;
    border-radius: 50px;
    background: var(--ion-color-primary);
    color: #2a3137;
    display: inline-block;
  }

  span:last-child {
    background: var(--ion-color-danger);
  }

}
beping-side-pane-layout > ion-split-pane > ion-menu {
  --side-max-width: 23%;
}
.score {
  font-weight: 700;
}

.ion-no-padding-horizontal {
  --padding-start: var(--ion-padding, 0px);
  --padding-end: var(--ion-padding, 0px);
  padding-left: var(--ion-padding, 0px);
  padding-right: var(--ion-padding, 0px);
}

.ion-no-margin-horizontal {
  --margin-start: var(--ion-padding, 0px);
  --margin-end: var(--ion-padding, 0px);
  margin-left: var(--ion-padding, 0px);
  margin-right: var(--ion-padding, 0px);
}

.ion-no-margin-vertical {
  --margin-top: 4px !important;
  --margin-bottom: 4px !important;
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

ion-popover {
  --max-height: 500px;

  .popover-wrapper {
    .popover-content {
      width: 80vw;
      max-width: 500px;
    }
  }
}

.custom-popover {

  --backdrop-opacity: 40%;
  --background: black;
}

.no-result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 80%;
  margin: auto;
  text-align: center;

  h3 {
    //font-size: 23px;
  }

  ion-icon {
    //font-size: 35px;
  }
}

.intro-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 70%;
  margin: auto;
  text-align: center;

  h3 {
    //font-size: 20px;
  }

  ion-icon {
    // font-size: 35px;
  }
}

hr {
  display: block;
  height: 0 !important;
  border-width: 1px !important;
  border-top: 1px solid var(--ion-color-grey-shade);
  margin: 1em;
}

.region-icon {
  svg {
    fill: var(--ion-color-primary)
  }

}

.white {
  transition: color 0.8s linear;
}

.shining {
  overflow: hidden;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.0), #fff, rgba(255, 255, 255, 0.0));
  background-repeat: no-repeat;
  background-size: 80%;
  color: rgba(255, 255, 255, 0.5);
  transition: color 0.5s linear;
  animation: shiningKeyFrame 1s linear infinite;
  -webkit-background-clip: text;
  // -webkit-text-fill-color: rgba(255, 255, 255, 0.5);
}

@keyframes shiningKeyFrame {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}

.multiline {
  white-space: pre-wrap !important;
}

beping-explore-container {
  background-color: var(--ion-color-primary);
}
.list-md.list-inset ion-item:first-child{
  --border-width: 0px;
}
.list-md.list-inset ion-item:last-child{
  --inner-border-width: 0px;
}
